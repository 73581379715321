import React from 'react'
import PostSection from '../PostSection';
import './ActualitesSection.scss';
import Fade from 'react-reveal/Fade';

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const lastTreePosts = posts => {
    const filterPost = [];
    const limit = 3;
    if (posts.length) {
        for (let i = 0; i < limit; i++) {
            filterPost[i] = posts[i]
        }
    }

    return filterPost;
};

class ActualitesSection extends React.Component {
    static defaultProps = {
        title: '',
        type: '',
        posts: []
    };


    render() {
        const {title, posts} = this.props;
        const filterPosts = lastTreePosts(posts);
        return (
            <div className="ActualitesSection p-8 text-center">
                <Fade>
                {title && <h2 className="ActualitesSection--Title">{title}</h2>}
                <hr></hr>
                </Fade>
                <Fade>
                {!!filterPosts.length && (
                    <section className="section">
                        <div className="container">
                            <PostSection posts={filterPosts}/>
                        </div>
                    </section>
                )}
                </Fade>
                <a href="/actualites"><button className="Button mt-4">Tous les articles</button></a>
            </div>
        )
    }
}

export default ActualitesSection
